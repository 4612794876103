<script setup>
import {onMounted, ref} from 'vue';

defineProps({
    modelValue: String,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({
  focus: () => input.value.focus(),
  addEventListener: (event, callback) => input.value.addEventListener(event, callback),
  removeEventListener: (event, callback) => input.value.removeEventListener(event, callback),
});
</script>

<template>
    <input
        ref="input"
        class="border-slate-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-slate-500 dark:focus:border-indigo-600 focus:ring-slate-500 dark:focus:ring-indigo-600 rounded-md shadow-sm"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
